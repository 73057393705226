import { CartUpsell } from './bundled-cart-upsell.component.js';
import { CartTiers } from './bundled-cart-tiers.directive.js';
import { TextPreview } from './bundled-text-preview.directive.js';
import { a as autobind } from './bundled-index2.js';
import { AddToCart } from './bundled-add-to-cart.directive.js';
import { C as Carousel } from './bundled-carousel.directive.js';
import { ScrollTo } from './bundled-scroll-to.directive.js';
import { ProductCard } from './bundled-product-card.directive.js';
import { Combobox } from './bundled-combobox.directive.js';
import { QuickCartItem } from './bundled-quick-cart-item.directive.js';
import { DetailsAccordion } from './bundled-details-accordion.directive.js';
import { BuyBlock } from './bundled-buy-block.directive.js';
import { D as Directive } from './bundled-Directive.js';
import { O as Ohm } from './bundled-app.js';
import './bundled-CartManager.js';
import './bundled-NotificationManager.js';
import './bundled-utils.js';
import './bundled-BehaviorSubject.js';
import './bundled-ShopifyUtils.js';
import './bundled-index3.js';
import './bundled-directive2.js';
import './bundled-Component.js';
import './bundled-unsafe-html.js';
import './bundled-when.js';
import './bundled-HostAttr.js';
import './bundled-Translation.js';
import './bundled-HostChildren.js';
import './bundled-HostChild.js';
import './bundled-ModalManager.js';

function e(e,t){if("object"==typeof Reflect&&"function"==typeof Reflect.metadata)return Reflect.metadata(e,t)}class AjaxDirectives extends Directive{onAppInit(){this.observer.observe(this.host,{childList:!0,subtree:!0});}onMutation(){this.updateDirective();}updateDirective(){this.innerModule?this.innerModule.update():this.innerModule=Ohm.module(`ajax-directives_${this.ohmID}`,{moduleHost:this.host,directives:[CartTiers,CartUpsell,TextPreview,AddToCart,Carousel,ScrollTo,ProductCard,Combobox,QuickCartItem,DetailsAccordion,BuyBlock]}).bootstrapModule();}constructor(...e){super(...e),this.observer=new MutationObserver(this.onMutation);}}AjaxDirectives.selector="[ajax-directives]",function(e,t,i,r){var o,c=arguments.length,s=c<3?t:null===r?r=Object.getOwnPropertyDescriptor(t,i):r;if("object"==typeof Reflect&&"function"==typeof Reflect.decorate)s=Reflect.decorate(e,t,i,r);else for(var n=e.length-1;n>=0;n--)(o=e[n])&&(s=(c<3?o(s):c>3?o(t,i,s):o(t,i))||s);c>3&&s&&Object.defineProperty(t,i,s);}([autobind,e("design:type",Function),e("design:paramtypes",[]),e("design:returntype",void 0)],AjaxDirectives.prototype,"onMutation",null);

export { AjaxDirectives };
